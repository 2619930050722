<template>
  <div>
    <h4>
      {{ titleText }}
    </h4>
    <div class="text-left">
      <div class="grid grid-cols-1 sm:grid-cols-2 pt-2 pb-1 px-2">
        <div v-if="cardImage" class="mb-2">
          <h5 class="text-xs leading-normal mb-4">{{ afterText }}</h5>
          <div class="text-xs mb-1">{{ subTextOne }}</div>
          <img :src="cardImage" :alt="state" height="160" />
        </div>
        <div v-if="secondCardImage" class="mt-2 mb-2">
          <h5 class="text-xs leading-normal mb-4">{{ beforeText }}</h5>
          <div class="text-xs mb-1">{{ subTextTwo }}</div>
          <img :src="secondCardImage" :alt="state" height="160" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        cardImage: "",
        secondCardImage: "",
        titleText: "",
        subTextOne: "",
        subTextTwo: "",
        afterText: "",
        beforeText: "",
      }
    },
    props: {
      state: {
        type: String,
      },
      licensePopup: {
        type: Boolean
      }
    },
    methods: {
      handleClosePopup() {
        this.$emit("handleCloseLicensePopup");
      },

      getTooltipData(value) {
        this.secondCardImage = "";
        this.afterText = "";
        this.beforeText = "";
        this.subTextOne = "";
        this.subTextTwo = "";
        switch (value) {
          case "ACT":
            this.titleText = "Australian Capital Territory - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/ACT.png");
            this.subTextOne = "Front of licence";
            break;

          case "NSW":
            this.titleText = "New South Wales - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/NSW.png");
            this.subTextOne = "Front of licence";
            break;

          case "NT":
            this.titleText = "Northern Territory - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/NT-After.png");
            this.secondCardImage = require("@/assets/images/licenseCards/NT-Before.png");
            this.afterText = "After 1 Nov 2020";
            this.subTextOne = "Back of licence";
            this.beforeText = "Before 1 Nov 2020 ";
            this.subTextTwo = "Back of licence";
            break;

          case "QLD":
            this.titleText = "Queensland - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/QLD-Front.png");
            this.secondCardImage = require("@/assets/images/licenseCards/QLD-Back.png");
            this.afterText = "AFTER 12 JUN 2019";
            this.subTextOne = "Front of licence";
            this.beforeText = "BEFORE 12 JUN 2019 ";
            this.subTextTwo = "Back of licence";
            break;

          case "SA":
            this.titleText = "South Australia - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/SA.png");
            this.subTextOne = "Back of licence";
            break;

          case "TAS":
            this.titleText = "Tasmania - location of licence card number";
            this.subTextOne = "Back of licence";
            this.cardImage = require("@/assets/images/licenseCards/TAS.png");
            break;

          case "VIC":
            this.titleText = "Victoria - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/VIC-After.png");
            this.secondCardImage = require("@/assets/images/licenseCards/VIC-Before.png");
            this.afterText = "AFTER 7 NOV 2022";
            this.subTextOne = "Back of licence";
            this.beforeText = "BEFORE 7 NOV 2022";
            this.subTextTwo = "Back of licence";
            break;

          case "WA":
            this.titleText = "Western Australia - location of licence card number";
            this.cardImage = require("@/assets/images/licenseCards/WA.png");
            this.subTextOne = "Back of licence";
            break;
        }
      }
    },
    mounted() {
      this.getTooltipData(this.$route.query.state);
    }
  };
</script>

